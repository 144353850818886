
<template>
  <div class="wrapper">
    <batch-nav
      @change="handleDateChange"
      @search="handleSearchBatch"
    ></batch-nav>
    <!-- <el-row :gutter="20">
      <el-col :span="3">
        <el-input v-model="keyWord" placeholder="请输入关键字"></el-input>
      </el-col>
      <el-col :span="3">
        <el-button type="primary" icon="el-icon-search">搜索</el-button>
      </el-col>
      <el-col :span="3">
        <el-button @click="refresh" icon="el-icon-refresh" type="warning"
          >刷新</el-button
        >
      </el-col>
    </el-row> -->
    <table-container height="720px" :list="batchList" :summary="false">
      <el-table-column type="index" label="排序" width="50" fixed>
      </el-table-column>
      <el-table-column prop="id" label="ID" fixed> </el-table-column>
      <el-table-column prop="name" label="批次名" fixed> </el-table-column>
      <el-table-column prop="date" label="创建时间"> </el-table-column>
      <el-table-column prop="batch_no" label="批次号"> </el-table-column>
      <el-table-column prop="image_num" label="图片数量"> </el-table-column>
      <el-table-column prop="order_item_num" width="120" label="订单项数量">
      </el-table-column>
      <el-table-column prop="hasProblem" width="120" label="问题件数量">
      </el-table-column>
      <el-table-column prop="status_text" width="120" label="状态">
      </el-table-column>
      <!-- <el-table-column label="打壳">
        <el-table-column prop="dk_info.admin_time" label="完成时间">
        </el-table-column>

        <el-table-column prop="dk_info.problem" label="问题"> </el-table-column>

        <el-table-column prop="dk_info.admin_name" label="操作人">
        </el-table-column>
      </el-table-column> -->
      <!-- <el-table-column label="点胶">
        <el-table-column prop="dj_info.admin_time" label="完成时间">
        </el-table-column>

        <el-table-column prop="dj_info.problem" label="问题"> </el-table-column>

        <el-table-column prop="dj_info.admin_name" label="操作人">
        </el-table-column>
      </el-table-column> -->
      <!-- <el-table-column label="发货">
        <el-table-column prop="fh_info.admin_time" label="完成时间">
        </el-table-column>

        <el-table-column prop="fh_info.problem" label="问题"> </el-table-column>

        <el-table-column prop="fh_info.admin_name" label="操作人">
        </el-table-column>
      </el-table-column> -->
      <el-table-column label="操作" fixed="right" width="220">
        <template slot-scope="scope">
          <el-button type="text" @click="lookOrderItem(scope.row)"
            >详情</el-button
          >
          <el-button type="text" @click="genBatchPackFile(scope.row)"
            >打包</el-button
          >

          <el-button type="text" @click="downLoadFile(scope.row.id)"
            >下载</el-button
          >
          <el-button type="text" @click="updateBatchExpressNo(scope.row.id)"
            >快递</el-button
          >
        </template>
      </el-table-column>
    </table-container>
    <el-pagination
      background
      layout="total,prev, pager, next,sizes"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      @size-change="sizeChange"
      :hide-on-single-page="batchList.length <= 5"
      @current-change="changePage"
    >
    </el-pagination>
    <orderItemBatch
      :dialogTableVisible1="dialogTableVisible1"
      :curBatchId="curBatchId"
      @changeVisible="changeVisible"
    />
  </div>
</template>

<script>
import BatchNav from "./components/Nav";
import orderItemBatch from "../orderItemBacth/index";
export default {
  components: {
    orderItemBatch,
    BatchNav,
  },
  props: {},
  inject: ["reload"],
  data() {
    return {
      batchList: [],
      total: 0,
      page: 0,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      keyWord: "",
      // filetypeRadio: '',
      dialogTableVisible1: false,
      curBatchId: "",
      startTime: "",
      endTime: "",
    };
  },
  watch: {},
  computed: {},
  async created() {
    this.getAllBatch();
  },
  mounted() {},
  methods: {
    //获取所有批次
    async getAllBatch() {
      const res = await this.$store.dispatch("getAllBatch", {
        page: this.page,
        pageSize: this.pageSize,
        startTime: this.startTime,
        endTime: this.endTime,
      });
      this.batchList = res.batches;
      this.total = res.total;
    },
    //搜索
    async handleSearchBatch(searchText) {
      const res = await this.$store.dispatch("getAllBatch", {
        searchText,
      });
      this.batchList = res.batches;
      this.total = res.total;
    },
    //时间改变
    handleDateChange({ startTime, endTime }) {
      this.startTime = startTime;
      this.endTime = endTime;
      this.getAllBatch();
    },
    //父子双向传递
    changeVisible(data) {
      this.dialogTableVisible1 = data;
    },
    //查看批次订单项详情
    lookOrderItem(scope) {
      this.dialogTableVisible1 = true;
      this.curBatchId = scope.id;
    },
    sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.page = 0;
      this.getBatches();
    },
    //快递页码改变事件
    async changePage(page) {
      //获取所有批次
      this.page = page;
      this.getBatches();
    },
    async getBatches() {
      let data = await this.$api.flowApi.getBatches({
        page: this.page,
        pageSize: this.pageSize,
      });
      if (data.code != 0) {
        this.$message({
          message: "批次列表获取失败",
          type: "error",
        });
        return;
      }
      this.batchList = data.batches;
      this.total = data.total;
    },
    //手动打包成图片或订单文件
    async genBatchPackFile(scope) {
      let data = await this.$api.flowApi.genBatchPackFile({
        batch_ids: JSON.stringify([scope.id]),
      });
      if (data.code != 0) {
        this.$message({
          message: "打包失败",
          type: "error",
        });
        return;
      }
      if (data.code == 0) {
        this.$message({
          message: "打包成功",
          type: "success",
        });
        this.reload();
      }
    },
    async downLoadFile(id) {
      //   if (this.filetypeRadio === '') {
      //     this.$message({
      //       message: '请选择下载的文件类型',
      //       type: 'warning',
      //     })
      //     return
      //   }
      let data = await this.$api.flowApi.downloadBatchFile({
        batch_id: id,
        file_type: 0,
      });
      if (data.code == 0) {
        window.location.href = data.url;
      }
      if (data.code != 0) {
        this.$message({
          message: data.msg,
          type: "error",
        });
        return;
      }
    },
    async updateBatchExpressNo(batchid) {
      let data = await this.$api.flowApi.updateBatchExpressNo({
        batch_id: batchid,
      });

      if (data.code == 0) {
        this.$message({
          message: "成功更新快递单号",
          type: "success",
        });
        return;
      } else {
        this.$message({
          message: data.msg,
          type: "error",
        });
        return;
      }
    },
    //刷新
    refresh() {
      this.reload();
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper {
  width: 100%;
  /deep/.el-pagination {
    text-align: left;
  }
  /deep/.el-popover__reference {
    border: none;
    padding: 0;
    margin: 0;
    color: #3a8ee6;
  }
}
</style>
