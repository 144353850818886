<template>
  <div class="wrapper2">
    <el-dialog
      title="订单项列表"
      width="80%"
      :visible.sync="dialogTableVisible"
      @open="openDialog"
    >
      <table-container :list="gridData">
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column property="id" label="ID" width="50"></el-table-column>
        <el-table-column
          v-for="field in fields"
          :label="field.name"
          :key="field.name"
          :prop="field.name_en"
        >
          <template slot-scope="scope">
            <span>{{ getAttrValue(scope) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图片" property="cropimage">
          <template slot-scope="scope">
            <div v-if="scope.row.sides.length > 0" style="z-index: 99999">
              <viewer
                :images="[baseUrl + scope.row.sides[0].cropimage]"
                style="z-index: 9999"
              >
                <img
                  :src="baseUrl + scope.row.sides[0].cropimage"
                  alt=""
                  style="width: auto; height: 60px"
                />
              </viewer>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          property="created_at"
          label="日期"
          width="150"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              @click="lookImage(scope.row)"
              v-if="scope.row.image_sides != ''"
              >图片详情</el-button
            >
          </template>
        </el-table-column>
      </table-container>
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        :total="total"
        :current-page.sync="page"
        :hide-on-single-page="gridData.length <= 5"
        @current-change="changePage"
      >
      </el-pagination>
      <el-dialog
        width="80%"
        title="订单项图片"
        :visible.sync="innerVisible"
        :append-to-body="true"
      >
        <template slot="title">
          <div>订单项图片</div>
          <!-- <el-button type="primary" size="mini" style="float:right;margin:10px 0" @click="addProblem">添加问题件</el-button> -->
        </template>
        <orderItemImage
          :sides="sides"
          :dialogFormVisible1="dialogFormVisible1"
          @changeProblem="changeProblem"
          :order_item_id="order_item_id"
        />
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import orderItemImage from "../orderItemImage/index";
export default {
  components: {
    orderItemImage,
  },
  props: ["dialogTableVisible1", "curBatchId"],
  data() {
    return {
      baseUrl: "",
      dialogTableVisible: false,
      total: 0,
      page: 0,
      pageSize: 10,
      pageSizes: [10, 20, 30, 40, 50],
      gridData: [],
      fields: [],
      innerVisible: false,
      sides: [],
      currentPage1: 1,
      dialogFormVisible1: false,
      order_item_id: "",
    };
  },
  watch: {
    dialogTableVisible1(newVal, oldVal) {
      this.dialogTableVisible = newVal;
    },
    dialogTableVisible(newVal, oldVal) {
      this.$emit("changeVisible", newVal);
    },
  },
  computed: {},
  methods: {
    getAttrValue(scope) {
      return scope.row.attrs[scope.column.property].value;
    },
    //订单页码改变事件
    async changePage(page) {
      this.page = page;
      this.getBatchItems();
    },
    //弹框打开回调
    async openDialog() {
      this.getBatchItems();
    },
    async getBatchItems() {
      let data = await this.$api.flowApi.getBatchItems({
        batch_id: this.curBatchId,
        page: this.page,
        pageSize: this.pageSize,
      });
      if (data.code != 0) {
        this.$message({
          message: "获取订单项失败",
          type: "error",
        });
        return;
      }
      this.gridData = Object.values(data.items);
      this.fields = data.fields;
      this.total = data.total;
    },
    //打开内层dialog
    async lookImage(scope) {
      this.order_item_id = scope.id;
      let data = await this.$api.flowApi.getOrderItemImages({
        order_item_id: scope.id,
      });
      if (data.code != 0) {
        this.$message({
          message: "获取订单项失败",
          type: "error",
        });
        return;
      }
      this.sides = data.sides;
      this.innerVisible = true;
    },
    //打开问题件弹框
    addProblem() {
      this.dialogFormVisible1 = true;
    },
    changeProblem(data) {
      this.dialogFormVisible1 = data;
    },
  },
  created() {
    this.baseUrl = this.base_url;
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.wrapper2 {
  width: 100%;
}
</style>
